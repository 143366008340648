import React from 'react'
import * as P from 'ts-prime'
import i18n from './i18n'
export type Language = 'pl' | 'lt' | 'en'

export const languages = ['pl', 'lt', 'en'] as const

interface LanguageCtx {
	language: Language
	setLanguage: (lng: Language) => void
	customerSupport: Record<Language, { phone: string; email: string, phoneBrb?: string, emailBrb?: string }>
}

const ConfigContext = React.createContext<LanguageCtx | null>(null)

export function useLanguageConfigContext() {
	const ctx = React.useContext(ConfigContext)
	if (ctx == null) throw new Error('LanguageConfig context not provided!')
	return ctx
}

export function useCustomerSupport() {
	const { language, customerSupport } = useLanguageConfigContext()
	return customerSupport[language]
}

export function LanguageConfigProvider(props: React.PropsWithChildren<{}>) {
	const [language, setLanguage] = React.useState<Language>('lt')
	React.useEffect(() => {
		const [lng] = window.location.hostname.split('.').slice(-1)
		if (P.isOneOf(lng, languages)) {
			setLanguage(lng)
			return
		}
	}, [])

	React.useEffect(() => {
		i18n.changeLanguage(language)
	}, [language])

	return (
		<ConfigContext.Provider
			value={{
				customerSupport: {
					lt: {
						phone: '+370 615 75529',
						email: 'info@barboraexpress.lt',
						phoneBrb: '(8 5) 230 9309',
						emailBrb: 'pagalba@barbora.lt'
					},
					en: {
						phone: '+370 615 75529',
						email: 'info@barboraexpress.lt',
						phoneBrb: '(8 5) 230 9309',
						emailBrb: 'pagalba@barbora.lt'
					},
					pl: {
						phone: '+48 22 153 00 05',
						email: 'bok@barbora.pl',
					},
				},
				language,
				setLanguage,
			}}
		>
			{props.children}
		</ConfigContext.Provider>
	)
}

import { Outlet, ReactLocation, Router } from '@tanstack/react-location'
import OrderTrackingPage from '../../pages/OrderTrackingPage'
import { APIProvider } from '../../_generated/client'

export interface RoutingInterface {
	Params: {
		trackingId: string
	}
}

export function routingParam<T extends keyof RoutingInterface['Params']>(k: T) {
	return `:${k}`
}

export const Location = new ReactLocation<RoutingInterface>()

export function Routing() {
	return (
		<Router
			routes={[
				{
					path: routingParam('trackingId'),
					element: (
						<APIProvider>
							<OrderTrackingPage />
						</APIProvider>
					),
				},
			]}
			location={Location}
		>
			<Outlet />
		</Router>
	)
}

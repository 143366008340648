import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const QuestionMarkIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon {...props} width={24} height={24} viewBox="0 0 24 24">
			<path
				fillRule="evenodd"
				d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
			/>
			<path d="M13 18.5039H11V16.5039H13V18.5039Z" />
			<path d="M13.4169 13.7239C13.4069 13.7339 13.3969 13.7539 13.3869 13.7739C13.3369 13.8539 13.2869 13.9339 13.2469 14.0139C13.2269 14.0439 13.2169 14.0839 13.2069 14.1239C13.1769 14.1939 13.1469 14.2639 13.1269 14.3339C13.0569 14.5439 13.0269 14.7639 13.0269 15.0139H11.0169C11.0169 14.5039 11.0969 14.0739 11.2169 13.7139C11.2169 13.7039 11.2169 13.6939 11.2269 13.6839C11.2369 13.6439 11.2669 13.6239 11.2769 13.5839C11.3369 13.4239 11.4069 13.2839 11.4969 13.1439C11.5269 13.0939 11.5669 13.0439 11.5969 12.9939C11.6269 12.9539 11.6469 12.9039 11.6769 12.8739L11.6869 12.8839C12.5269 11.7839 13.8969 11.4439 14.0069 10.2039C14.0969 9.22393 13.3969 8.27393 12.4369 8.07393C11.3969 7.85393 10.4569 8.46393 10.1369 9.35393C9.99688 9.71393 9.66688 10.0039 9.25688 10.0039H9.05688C8.45688 10.0039 8.01688 9.41393 8.18688 8.83393C8.73688 7.01393 10.5569 5.74393 12.6169 6.04393C14.3069 6.29393 15.6569 7.68393 15.9469 9.37393C16.3869 11.8139 14.3169 12.4039 13.4169 13.7239V13.7239Z" />
		</SvgIcon>
	)
}

export default QuestionMarkIcon

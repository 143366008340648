import * as React from 'react'
import { OrderTrackingContent } from './OrderTrackingContent'
import { OrderTrackingProvider } from './OrderTrackingProvider'
import OrderBar from '../../components/OrderBar/OrderBar'
import Container from '@mui/material/Container'
import { styled, ThemeProvider } from '@mui/material/styles'
import { theme } from '../../assets/theme'
import Box from '@mui/material/Box'

const BodyBox = styled(Box)(() => ({
	width: '100%',
	maxWidth: '100vw',
	height: '100%',
	minHeight: '100vh',
	backgroundColor: '#E5E5E5',
}))

const AppBarStub = styled(Box)(({ theme }: any) => ({
	backgroundColor: 'transparent',
	height: theme.sizes.toolbar.height.big,

	[theme.breakpoints.up('md')]: {
		height: theme.sizes.toolbar.height.small,
	},

	[`${theme.breakpoints.down('md')} and (orientation: landscape)`]: {
		height: theme.sizes.toolbar.height.small,
	},
}))

const CustomContainer = styled(Container)(({ theme }: any) => ({
	paddingLeft: '0px',
	paddingRight: '0px',
	paddingBottom: '32px',
	paddingTop: '0',

	[theme.breakpoints.up('sm')]: {
		paddingTop: '32px',
	},

	[theme.breakpoints.up('md')]: {
		paddingLeft: '24px',
		paddingRight: '24px',
	},

	[`${theme.breakpoints.down('md')} and (orientation: landscape)`]: {
		paddingTop: '0',
	},
}))

export default function OrderTrackingPage() {
	return (
		<OrderTrackingProvider>
			<ThemeProvider theme={theme}>
				<BodyBox>
					<OrderBar />
					<AppBarStub />
					<CustomContainer maxWidth="lg">
						<OrderTrackingContent />
					</CustomContainer>
				</BodyBox>
			</ThemeProvider>
		</OrderTrackingProvider>
	)
}

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import OrderHistory from '../../components/OrderHistory/OrderHistory'
import OrderStatusComponent from '../../components/OrderStatus/OrderStatus'
import OrderTrackingMap from '../../components/OrderTrackingMap/OrderTrackingMap'

export function OrderTrackingContent() {
	const theme = useTheme()
	const downMd = useMediaQuery(theme.breakpoints.down('md'))

	return (
		<Grid container spacing={downMd ? 0 : '32px'}>
			<Grid item xs={12} md={8} sx={{ order: { xs: 1, md: 2 } }}>
				<OrderTrackingMap />
			</Grid>
			<Grid item xs={12} md={4} sx={{ order: { xs: 2, md: 1 } }}>
				<Box sx={{ display: 'flex' }}>
					<Grid container direction="column" spacing="32px">
						<Grid item xs="auto">
							<OrderStatusComponent />
						</Grid>
						<Grid item xs="auto">
							<OrderHistory />
						</Grid>
					</Grid>
				</Box>
			</Grid>
		</Grid>
	)
}

export function Page404() {
	return (
		<div>
			<h1>Order was not found!</h1>
		</div>
	)
}

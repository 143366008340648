import Paper from '@mui/material/Paper'
import * as MapboxGl from 'mapbox-gl'
import { useEffect, useRef, useState } from 'react'
import Map, { MapRef, Marker, NavigationControl } from 'react-map-gl'
import { useOrderTrackingContext } from '../../pages/OrderTrackingPage/OrderTrackingProvider'
import CartIcon from '../CustomSvgIcons/CartIcon'
import DeliveryHomeIcon from '../CustomSvgIcons/DeliveryHomeIcon'
import ProfileIcon from '../CustomSvgIcons/ProfileIcon'
import MapMarker from '../MapMarker/MapMarker'

const initialViewState = {
	latitude: 54.526,
	longitude: 15.2551,
	zoom: 2,
	bearing: 0,
	pitch: 0,
}

const settings = {
	scrollZoom: true,
	boxZoom: true,
	dragRotate: true,
	dragPan: true,
	keyboard: true,
	doubleClickZoom: true,
	touchZoomRotate: true,
	touchPitch: false,
	minZoom: 2,
	maxZoom: 20,
	minPitch: 0,
	maxPitch: 0,
}

function OrderTrackingMap() {
	const data = useOrderTrackingContext()
	const mapRef = useRef<MapRef>(null)
	const [isMapLoaded, setMapLoadState] = useState(false)

	const dropOffLng = data?.dropOff.coordinates?.lng
	const dropOffLat = data?.dropOff.coordinates?.lat
	const pickupLng = data?.pickup.coordinates?.lng
	const pickupLat = data?.pickup.coordinates?.lat
	const courierLng = data?.courierLocation?.lng
	const courierLat = data?.courierLocation?.lat

	function isEmpty(value: any) {
		return value == null
	}
	function fitBounds(lng_1: number, lat_1: number, lng_2: number, lat_2: number) {
		mapRef.current?.fitBounds(
			[
				[lng_1, lat_1],
				[lng_2, lat_2],
			],
			{ padding: 60, animate: true, duration: 1000 }
		)
	}

	useEffect(() => {
		if (isEmpty(dropOffLng) || isEmpty(dropOffLat) || isEmpty(pickupLng) || isEmpty(pickupLat)) {
			return
		}
		fitBounds(dropOffLng as number, dropOffLat as number, pickupLng as number, pickupLat as number)
	}, [dropOffLng, dropOffLat, pickupLng, pickupLat, isMapLoaded])

	useEffect(() => {
		if (isEmpty(dropOffLng) || isEmpty(dropOffLat) || isEmpty(courierLng) || isEmpty(courierLat)) {
			return
		}
		fitBounds(dropOffLng as number, dropOffLat as number, courierLng as number, courierLat as number)
	}, [dropOffLng, dropOffLat, courierLng, courierLat])

	const onMapLoaded = (e: any) => {
		setMapLoadState(true)
	}

	function renderDropOffMarker() {
		if (isEmpty(dropOffLng) || isEmpty(dropOffLat)) {
			return null
		}
		return (
			<Marker longitude={dropOffLng} latitude={dropOffLat} anchor="bottom">
				<MapMarker
					size="30px"
					icon={
						<DeliveryHomeIcon
							sx={{
								fontSize: '20px',
								position: 'absolute',
								left: 'calc(50% - 20px / 2)',
								top: 'calc(50% - 20px / 2 - 1px)',
							}}
						></DeliveryHomeIcon>
					}
				/>
			</Marker>
		)
	}

	function renderPickupMarker() {
		if (isEmpty(pickupLng) || isEmpty(pickupLat)) {
			return null
		}
		return (
			<Marker longitude={pickupLng} latitude={pickupLat} anchor="bottom">
				<MapMarker
					size="30px"
					icon={
						<CartIcon
							sx={{
								fontSize: '20px',
								position: 'absolute',
								left: 'calc(50% - 20px/2 - 1px)',
								top: 'calc(50% - 20px/2 + 1px)',
							}}
						></CartIcon>
					}
				/>
			</Marker>
		)
	}

	function renderCourierMarker() {
		if (isEmpty(courierLng) || isEmpty(courierLat)) {
			return null
		}
		return (
			<Marker longitude={courierLng as number} latitude={courierLat as number} anchor="bottom">
				<MapMarker
					size="30px"
					icon={
						<ProfileIcon
							sx={{
								fontSize: '20px',
							}}
						></ProfileIcon>
					}
				/>
			</Marker>
		)
	}

	return (
		<>
			<Paper
				sx={{
					height: {
						xs: 'calc(100vw)',
						sm: 'calc(100vw - 24px - 24px)',
						md: 'calc(100vh - 32px - 32px - 60px)',
					},
					maxHeight: { xs: '50vh', sm: '50vh', md: '1000px' },
					minHeight: '250px',
					p: 0,
				}}
				elevation={25}
			>
				{MapboxGl.supported() ? (
					<Map
						ref={mapRef}
						initialViewState={initialViewState}
						onLoad={onMapLoaded}
						{...settings}
						mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
						style={{ maxWidth: '100%', maxHeight: '100%', minHeight: '100%', minWidth: '100%' }}
						mapStyle="mapbox://styles/mapbox/streets-v11"
					>
						{renderDropOffMarker()}
						{renderPickupMarker()}
						{renderCourierMarker()}
						<NavigationControl position="top-right" showZoom={false} />
					</Map>
				) : null}
			</Paper>
		</>
	)
}

export default OrderTrackingMap

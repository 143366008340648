import { createRequester } from '@digimuza/graphql'
import { makeBasicContextComponent } from '@digimuza/react-utils'
import { GraphQLClient } from 'graphql-request'
import { createClient } from 'graphql-ws'
import { useMemo } from 'react'
import { useTrackingId } from '../modules/params/useTrackingId'
import { getSdk, Requester } from './graphql'
function generateSDK(args: { websocketUrl: string; restUrl: string; headers: { authorization?: string } }) {
	const headers = args.headers
	const req = createRequester({
		request: new GraphQLClient(args.restUrl, { headers: headers }),
		websocket: createClient({
			url: args.websocketUrl,
			connectionParams: {
				headers: headers,
			},
		}),
	})

	return req
}
const requester = makeBasicContextComponent<Requester>('Requester')

export function APIProvider(props: React.PropsWithChildren<{}>) {
	const tracking = useTrackingId()
	const req = useMemo(() => {
		return generateSDK({
			websocketUrl: process.env.REACT_APP_WS_HOST || '',
			restUrl: process.env.REACT_API_HOST || '',
			headers: { authorization: `Tracking ${tracking}` },
		})
	}, [tracking])
	return <requester.ContextProvider value={req}>{props.children}</requester.ContextProvider>
}

export function useSdk() {
	const req = requester.useContext()
	return getSdk(req)
}

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const CloseIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon {...props} width={80} height={80} viewBox="0 0 80 80">
			<path d="M60.9999 19.0333C60.3771 18.4091 59.5316 18.0583 58.6499 18.0583C57.7682 18.0583 56.9227 18.4091 56.2999 19.0333L39.9999 35.2999L23.6999 18.9999C23.0771 18.3758 22.2316 18.025 21.3499 18.025C20.4682 18.025 19.6227 18.3758 18.9999 18.9999C17.6999 20.2999 17.6999 22.3999 18.9999 23.6999L35.2999 39.9999L18.9999 56.2999C17.6999 57.5999 17.6999 59.6999 18.9999 60.9999C20.2999 62.2999 22.3999 62.2999 23.6999 60.9999L39.9999 44.6999L56.2999 60.9999C57.5999 62.2999 59.6999 62.2999 60.9999 60.9999C62.2999 59.6999 62.2999 57.5999 60.9999 56.2999L44.6999 39.9999L60.9999 23.6999C62.2666 22.4333 62.2666 20.2999 60.9999 19.0333Z" />
		</SvgIcon>
	)
}

export default CloseIcon

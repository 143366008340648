import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Dialog from '@mui/material/Dialog'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Slide from '@mui/material/Slide'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import { TransitionProps } from '@mui/material/transitions'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useCustomerSupport } from '../../modules/locale'
import BarboraLogo from '../CustomSvgIcons/BarboraLogo'
import CloseIcon from '../CustomSvgIcons/CloseIcon'
import EmailIcon from '../CustomSvgIcons/EmailIcon'
import PhoneIcon from '../CustomSvgIcons/PhoneIcon'
import QuestionMarkIcon from '../CustomSvgIcons/QuestionMarkIcon'
import { useOrderTrackingContext } from '../../pages/OrderTrackingPage/OrderTrackingProvider'

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />
})

const CustomToolbar = styled(Toolbar)(({ theme }: any) => ({
	height: theme.sizes.toolbar.height.big,

	[theme.breakpoints.up('md')]: {
		height: theme.sizes.toolbar.height.small,
	},

	[`${theme.breakpoints.down('md')} and (orientation: landscape)`]: {
		height: theme.sizes.toolbar.height.small,
	},
}))

const barboraOrgsIds = [
	"ead2268d-b2e6-4cf4-8114-0d83701a16ea", // staging
	"385e70c0-46b8-4431-87c9-23172500590e", // production
]

const OrderBar = () => {
	const { t } = useTranslation()
	const { email, phone, emailBrb, phoneBrb } = useCustomerSupport()
	const data = useOrderTrackingContext()

	const emailToDisplay = barboraOrgsIds.some(id => id === data.organizationId) ? emailBrb : email
	const phoneToDisplay = barboraOrgsIds.some(id => id === data.organizationId) ? phoneBrb : phone

	const CustomButton = styled(Button)({
		borderRadius: '50px',
		border: '2px solid #ffffff',

		'&:hover': {
			border: '2px solid #ffffff',
		},
		'&:active': {
			border: '2px solid #ffffff',
		},
		'&:focus': {
			border: '2px solid #ffffff',
		},
	})

	const [open, setOpen] = React.useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
			<AppBar sx={{ bgcolor: '#E22E39' }} elevation={0}>
				<Container
					sx={{
						pl: { xs: 0, sm: '24px' },
						pr: { xs: 0, sm: '24px' },
					}}
					maxWidth="lg"
				>
					<CustomToolbar disableGutters>
						<Box sx={{ mx: '16px', mt: '4px' }}>
							<BarboraLogo></BarboraLogo>
						</Box>

						<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end' }}>
							<CustomButton
								color="neutral2"
								variant="outlined"
								sx={{
									color: 'white',
									borderColor: 'white',
									mr: '16px',
									p: '6px 18px',
								}}
								onClick={handleClickOpen}
								aria-label="account of current user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
							>
								<Typography
									variant="button_custom"
									component="span"
									sx={{
										display: 'inline-flex',
										alignItems: 'center',
										textTransform: 'capitalize',
									}}
								>
									<QuestionMarkIcon	sx={{ fontSize: '24px', mr: '8px' }} />
									{t('order.help')}
								</Typography>
							</CustomButton>
						</Box>

						<Stack
							direction="row"
							spacing="24px"
							sx={{
								flexGrow: 1,
								display: { xs: 'none', md: 'flex' },
								justifyContent: 'flex-end',
								mr: '16px',
							}}
						>
							<Typography
								variant="subtitle1_custom"
								component="span"
								sx={{ display: 'inline-flex', alignItems: 'center' }}
							>
								<EmailIcon sx={{ mr: '8px' }} />

								<a
									style={{
										color: 'white',
										textDecoration: 'none',
									}}
									href={`mailTo:${emailToDisplay}`}
								>
									{emailToDisplay}
								</a>
							</Typography>
							<Typography
								variant="subtitle1_custom"
								component="span"
								sx={{ display: 'inline-flex', alignItems: 'center', mr: '24px' }}
							>
								<PhoneIcon sx={{ mr: '8px' }}></PhoneIcon>
								<a
									style={{
										color: 'white',
										textDecoration: 'none',
									}}
									href={`tel:${phoneToDisplay}`}
								>
									{phoneToDisplay}
								</a>
							</Typography>
						</Stack>
					</CustomToolbar>
				</Container>
			</AppBar>

			<Dialog
				fullWidth
				maxWidth="md"
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
				sx={{
					'& .MuiPaper-root': {
						m: 0,
						pb: '34px',
						width: '100%',
						borderRadius: { xs: '10px 10px 0 0', md: '10px' },
						bgcolor: '#F6F6F8',
					},
					'& .MuiDialog-container': { alignItems: { xs: 'flex-end', md: 'center' } },
				}}
			>
				<Stack
					direction="row"
					justifyContent="center"
					alignItems="center"
					sx={{ height: '72px', position: 'relative' }}
				>
					<Typography
						variant="h4_custom"
						component="span"
						sx={{ display: 'inline-flex', alignItems: 'center' }}
					>
						Help
					</Typography>
					<Button
						color="neutral"
						onClick={handleClose}
						sx={{
							position: 'absolute',
							right: '20px',
							minWidth: 'auto',
							m: 0,
							p: '8px',
							borderRadius: '50px',
						}}
					>
						<CloseIcon sx={{ fontSize: '24px', color: '#999999' }}></CloseIcon>
					</Button>
				</Stack>

				<List sx={{ p: 0 }}>
					<Divider />
					<ListItem divider sx={{ bgcolor: '#ffffff', boxShadow: '0px 1px 3px rgb(0 0 0 / 7%)' }}>
						<ListItemIcon sx={{ minWidth: '40px' }}>
							<EmailIcon sx={{ fontSize: '24px', color: '#3B3B3B' }}></EmailIcon>
						</ListItemIcon>
						<ListItemText
							primary={
								<Typography
									variant="subtitle2_custom"
									component="span"
									sx={{ display: 'inline-flex', alignItems: 'center' }}
								>
									{emailToDisplay}
								</Typography>
							}
						/>
					</ListItem>
					<ListItem divider sx={{ bgcolor: '#ffffff', boxShadow: '0px 1px 3px rgb(0 0 0 / 7%)' }}>
						<ListItemIcon sx={{ minWidth: '40px' }}>
							<PhoneIcon sx={{ fontSize: '24px', color: '#3B3B3B' }} />
						</ListItemIcon>
						<ListItemText
							primary={
								<Typography
									variant="subtitle2_custom"
									component="span"
									sx={{ display: 'inline-flex', alignItems: 'center', mr: '24px' }}
								>
									{phoneToDisplay}
								</Typography>
							}
						/>
					</ListItem>
				</List>
			</Dialog>
		</>
	)
}

export default OrderBar

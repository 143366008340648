import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const CartIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon {...props} width={16} height={16} viewBox="0 0 16 16">
			<path d="M1.33333 1.99998C1.33333 1.63179 1.63181 1.33331 2 1.33331H4.23529C4.52933 1.33331 4.78864 1.52594 4.87355 1.80744L5.47563 3.80331H14.6667C14.8866 3.80331 15.0925 3.91183 15.2167 4.09333C15.341 4.27483 15.3678 4.50596 15.2882 4.71105L13.0529 10.4744C12.9534 10.7309 12.7065 10.9 12.4314 10.9H7.21569C6.94052 10.9 6.69363 10.7309 6.59413 10.4744L4.35884 4.71105C4.35265 4.69509 4.34708 4.6789 4.34213 4.66252L3.74006 2.66665H2C1.63181 2.66665 1.33333 2.36817 1.33333 1.99998ZM5.95401 5.13665L7.67217 9.56665H11.9749L13.6931 5.13665H5.95401ZM8.75547 13.0834C8.75547 13.6081 8.33014 14.0334 7.80547 14.0334C7.2808 14.0334 6.85547 13.6081 6.85547 13.0834C6.85547 12.5588 7.2808 12.1334 7.80547 12.1334C8.33014 12.1334 8.75547 12.5588 8.75547 13.0834ZM11.3953 14.0334C11.92 14.0334 12.3453 13.6081 12.3453 13.0834C12.3453 12.5588 11.92 12.1334 11.3953 12.1334C10.8706 12.1334 10.4453 12.5588 10.4453 13.0834C10.4453 13.6081 10.8706 14.0334 11.3953 14.0334Z" />
		</SvgIcon>
	)
}

export default CartIcon

import { Coordinate } from '@barbora-express/coordinate'
import { makeBasicContextComponent, useObservable } from '@digimuza/react-utils'
import CircularProgress from '@mui/material/CircularProgress'
import * as React from 'react'
import { map } from 'rxjs'
import * as P from 'ts-prime'
import { OrderStatus } from '../../components/ProgressBar/ProgressBar'
import { useSdk } from '../../_generated/client'
import { OrderTrackingSubscription } from '../../_generated/graphql'
// subscription OrderTracking($trackingId: String!) {
// 	OrderTracking(trackingId: $trackingId) {
// 	  arrival
// 	  orderId
// 	  merchantOrderId
// 	  status
// 	  events {
// 		event
// 		createdAt
// 	  }
// 	  dropOff {
// 		coordinates {
// 		  lng
// 		  lat
// 		}
// 		address
// 	  }
// 	  courierLocation {
// 		lng
// 		lat
// 	  }
// 	  pickup {
// 		coordinates {
// 		  lng
// 		  lat
// 		}
// 		address
// 	  }
// 	}
//   }

function normalizeOrderTrackingSubscription(data: OrderTrackingSubscription) {
	const order = data.order[0]
	if (order == null) return null
	const tasks = P.mapToObj(order.tasks, (q) => [
		q.taskType as 'PICKUP' | 'DROP_OFF',
		{
			address: {
				coordinates: Coordinate.from(q.address.coordinates || []).latLng(),
				address: q.address.address,
			},
			eta: P.mapToObj(q.taskETA, (taskEta) => [taskEta.type as 'CURRENT' | 'INITIAL', new Date(taskEta.arrival)]),
		},
	])
	return {
		organizationId: order.organizationId,
		arrival: tasks.DROP_OFF.eta.CURRENT as Date | undefined,
		orderId: order.orderId,
		dropOff: tasks.DROP_OFF.address,
		pickup: tasks.PICKUP.address,
		courierLocation: order.courier?.lastLocation ? Coordinate.from(order.courier.lastLocation || []).latLng() : null,
		events: order.orderStatusEvents.map((q) => {
			return {
				event: q.type,
				createdAt: new Date(q.createdAt),
			}
		}),
		status: order.status as OrderStatus,
		merchantOrderId: order.orderId,
	}
}

export const OrderTrackingSubscriptionContext = makeBasicContextComponent<
	NonNullable<ReturnType<typeof normalizeOrderTrackingSubscription>>
>('TrackingSubscription')

export function useOrderTrackingContext() {
	const ctx = OrderTrackingSubscriptionContext.useContext()
	return ctx
}

export function OrderTrackingProvider(props: React.PropsWithChildren<{}>) {
	const sdk = useSdk()
	const data = useObservable(sdk.OrderTracking().pipe(map(normalizeOrderTrackingSubscription)), [])

	if (data == null)
		return (
			<CircularProgress
				color="error"
				variant="indeterminate"
				size={120}
				thickness={4}
				sx={{ position: 'absolute', left: 'calc(50% - 60px)', top: 'calc(50% - 60px)', color: '#E22E39' }}
			/>
		)

	return (
		<OrderTrackingSubscriptionContext.ContextProvider value={data}>
			{props.children}
		</OrderTrackingSubscriptionContext.ContextProvider>
	)
}

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { styled, useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import CircleSpinner from '../CircleSpinner/CircleSpinner'
import CheckIcon from '../CustomSvgIcons/CheckIcon'
import CloseIcon from '../CustomSvgIcons/CloseIcon'
import ProgressDetails from '../ProgressDetails/ProgressDetails'

const CustomCircularProgressbar = styled(CircularProgressbarWithChildren)(({ theme }) => ({
	'.CircularProgressbar-path': {
		// stroke: 'url(#bones-gradient)',
	},
	[theme.breakpoints.up('md')]: {},
}))

const ProgressTime = styled('span')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignContent: 'center',
	justifyContent: 'center',
	alignItems: 'center',

	[theme.breakpoints.up('md')]: {},
}))

export enum OrderStatus {
	Created = 'CREATED',
	Planned = 'PLANNED',
	Started = 'STARTED',
	Failed = 'FAILED',
	Completed = 'COMPLETED',
	NeedCourier = 'NEED_COURIER',
	CourierMissing = 'COURIER_MISSING',
	Active = 'ACTIVE',
}

function getProgressBarPathColor(state: OrderStatus | undefined) {
	switch (state) {
		case OrderStatus.Created:
			return '#F6F6F8'
		case OrderStatus.Planned:
			return '#F6F6F8'
		case OrderStatus.Active:
			return '#68C177'
		case OrderStatus.NeedCourier:
			return '#68C177'
		case OrderStatus.Started:
			return '#68C177'
		case OrderStatus.CourierMissing:
			return '#68C177'
		case OrderStatus.Completed:
			return '#42B255'
		case OrderStatus.Failed:
			return '#F9D5D7'
		default:
			return '#F6F6F8'
	}
}

function getProgressBarTrailColor(state: OrderStatus | undefined) {
	switch (state) {
		case OrderStatus.Created:
			return '#F6F6F8'
		case OrderStatus.Planned:
			return '#F6F6F8'
		case OrderStatus.Active:
			return '#F6F6F8'
		case OrderStatus.NeedCourier:
			return '#F6F6F8'
		case OrderStatus.Started:
			return '#F6F6F8'
		case OrderStatus.CourierMissing:
			return '#F6F6F8'
		case OrderStatus.Completed:
			return '#42B255'
		case OrderStatus.Failed:
			return '#F9D5D7'
		default:
			return '#F6F6F8'
	}
}

const ProgressBar = ({ state, percentage, time }: ProgressBarProps) => {
	const theme = useTheme()
	const downMd = useMediaQuery(theme.breakpoints.down('md'))

	return (
		<>
			<Box>
				<Grid container justifyContent="center">
					<Grid item xs="auto">
						<Box sx={{ position: 'relative' }}>
							<CustomCircularProgressbar
								sx={{
									width: {
										xs: '96px',
										md: '208px',
									},
									height: {
										xs: '96px',
										md: '208px',
									},
								}}
								value={percentage}
								strokeWidth={7}
								styles={buildStyles({
									rotation: 0,
									strokeLinecap: 'butt',
									pathTransitionDuration: 0.5,
									pathColor: getProgressBarPathColor(state),
									trailColor: getProgressBarTrailColor(state),
								})}
							></CustomCircularProgressbar>

							{state !== undefined && state !== 'FAILED' && state !== 'COMPLETED' ? (
								<CircleSpinner
									outerSize={downMd ? 96 : 208}
									innerSize={downMd ? 96 - 3 * 4 : 208 - 7 * 4}
								/>
							) : null}

							<Box
								sx={{
									position: 'absolute',
									top: 0,
									left: 0,
									height: { xs: '96px', md: '208px' },
									width: { xs: '96px', md: '208px' },
									background: 'transparent',
									zIndex: 5,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<ProgressTime>
									{state === OrderStatus.Created ||
									state === OrderStatus.Planned ||
									state === OrderStatus.Active ||
									state === OrderStatus.NeedCourier ||
									state === OrderStatus.Started ||
									state === OrderStatus.CourierMissing ? (
										<Typography
											align="center"
											variant={downMd ? 'h2_custom' : 'h1_custom'}
											component="span"
											sx={{ mb: { xs: 0, md: '3px' } }}
										>
											{time}
										</Typography>
									) : state === OrderStatus.Completed ? (
										<CheckIcon
											sx={{
												fontSize: { xs: '48px', md: '80px' },
												color: '#42B255',
											}}
										></CheckIcon>
									) : state === OrderStatus.Failed ? (
										<CloseIcon
											sx={{
												fontSize: { xs: '48px', md: '80px' },
												color: '#E22E39',
											}}
										></CloseIcon>
									) : null}

									{!downMd && (
										<ProgressDetails
											state={state}
											time={time}
										></ProgressDetails>
									)}
								</ProgressTime>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</>
	)
}

export default ProgressBar

interface ProgressBarProps {
	state: OrderStatus | undefined
	percentage: number
	time: number
}

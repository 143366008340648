import './App.css'
import { LanguageConfigProvider } from './modules/locale'
import { Routing } from './modules/location'

function App() {
	return (
		<LanguageConfigProvider>
			<Routing />
		</LanguageConfigProvider>
	)
}

export default App

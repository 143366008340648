import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const EllipseIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon {...props} width={12} height={12} viewBox="0 0 12 12">
			<circle cx={6} cy={6} r={6} />
		</SvgIcon>
	)
}

export default EllipseIcon

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { OrderStatus } from '../ProgressBar/ProgressBar'

const ProgressDetails = ({ state, time }: ProgressDetailsProps) => {
	const { t } = useTranslation()

	return (
		<>
			<Box
				sx={{
					width: {
						xs: '96px',
						md: '85px',
					},
					maxWidth: {
						xs: '96px',
						md: '85px',
					},
				}}
			>
				<Typography variant="body2_custom" component="div" align="center">
					{state === OrderStatus.Created ||
					state === OrderStatus.Planned ||
					state === OrderStatus.Active ||
					state === OrderStatus.Started ||
					state === OrderStatus.NeedCourier ||
					state === OrderStatus.CourierMissing
						? t('order.progress.minutes_few', { count: time })
						: state === OrderStatus.Completed
						? t('order.progress.delivered')
						: state === OrderStatus.Failed
						? t('order.progress.failed')
						: null}
				</Typography>
			</Box>
		</>
	)
}

export default ProgressDetails

interface ProgressDetailsProps {
	state: OrderStatus | undefined
	time: number
}

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const EmailIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon {...props} width={24} height={24} viewBox="0 0 24 24">
			<path
				fillRule="evenodd"
				d="M4.6585 5.24742L12 11.6712L19.3415 5.24742L20.6585 6.75258L13.317 13.1764C12.563 13.8362 11.437 13.8362 10.683 13.1764L3.3415 6.75258L4.6585 5.24742Z"
			/>
			<path
				fillRule="evenodd"
				d="M2 6C2 4.89543 2.89543 4 4 4H20C21.1046 4 22 4.89543 22 6V18C22 19.1046 21.1046 20 20 20H4C2.89543 20 2 19.1046 2 18V6ZM20 6L4 6V18H20V6Z"
			/>
		</SvgIcon>
	)
}
export default EmailIcon

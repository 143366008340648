import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const ProfileIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon {...props} width={16} height={16} viewBox="0 0 16 16">
			<path
				fillRule="evenodd"
				d="M9.6862 4.33331C9.6862 5.25379 8.94001 5.99998 8.01953 5.99998C7.09906 5.99998 6.35286 5.25379 6.35286 4.33331C6.35286 3.41284 7.09906 2.66665 8.01953 2.66665C8.94001 2.66665 9.6862 3.41284 9.6862 4.33331ZM11.0195 4.33331C11.0195 5.99017 9.67639 7.33331 8.01953 7.33331C6.36268 7.33331 5.01953 5.99017 5.01953 4.33331C5.01953 2.67646 6.36268 1.33331 8.01953 1.33331C9.67639 1.33331 11.0195 2.67646 11.0195 4.33331ZM3.33333 14.6666C3.33333 12.0026 5.4929 9.84308 8.15686 9.84308C10.8208 9.84308 12.9804 12.0026 12.9804 14.6666H14.3137C14.3137 11.2663 11.5572 8.50975 8.15686 8.50975C4.75652 8.50975 2 11.2663 2 14.6666H3.33333Z"
			/>
		</SvgIcon>
	)
}

export default ProfileIcon

import Avatar from '@mui/material/Avatar'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import { OrderIcon, useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import * as P from 'ts-prime'
import { useOrderTrackingContext } from '../../pages/OrderTrackingPage/OrderTrackingProvider'
import { OrderStatusTypesEnum } from '../../_generated/graphql'
import CartIcon from '../CustomSvgIcons/CartIcon'
import CloseIcon from '../CustomSvgIcons/CloseIcon'
import DeliveryHomeIcon from '../CustomSvgIcons/DeliveryHomeIcon'
import EllipseIcon from '../CustomSvgIcons/EllipseIcon'
import ProfileIcon from '../CustomSvgIcons/ProfileIcon'
import './styles.css'
function OrderHistory() {
	const { t } = useTranslation()
	const data = useOrderTrackingContext()

	const orderTracking = data
	if (orderTracking == null) return null

	const events = orderTracking.events

	return (
		<Paper sx={{ display: 'flex', p: '8px 16px' }} elevation={25}>
			<List sx={{ p: 0, width: '100%' }}>
				<TransitionGroup className="todo-list">
					{orderTracking.events
						.map((e, index) => {
							const dateTime = format(new Date(e.createdAt), 'yyyy MM dd, HH:mm')
							const isCurrent = index === events.length - 1

							if (e.event === OrderStatusTypesEnum.Created) {
								return (
									<HistoryItem
										key={e.event}
										title={t('order.state.received')}
										description={''}
										dateTime={dateTime}
										icon={
											<CartIcon
												sx={{
													fontSize: '16px',
													position: 'absolute',
													left: 'calc(50% - 16px/2 - 1px)',
													top: 'calc(50% - 16px/2 + 1px)',
												}}
											/>
										}
										isCurrent={isCurrent}
										isFailed={false}
										haveBg={false}
									/>
								)
							}

							if (e.event === OrderStatusTypesEnum.Active) {
								return (
									<HistoryItem
										key={e.event}
										title={t('order.state.pickedUp')}
										description={''}
										dateTime={dateTime}
										icon={
											isCurrent ? (
												<ProfileIcon
													sx={{ fontSize: '16px' }}
												></ProfileIcon>
											) : (
												<EllipseIcon
													sx={{ fontSize: '12px' }}
												></EllipseIcon>
											)
										}
										isCurrent={isCurrent}
										isFailed={false}
										haveBg={false}
									/>
								)
							}
							if (e.event === OrderStatusTypesEnum.Started) {
								return (
									<HistoryItem
										key={e.event}
										title={t('order.state.onTheWay')}
										description={''}
										dateTime={dateTime}
										icon={
											index === events.length - 1 ? (
												<ProfileIcon
													sx={{ fontSize: '16px' }}
												></ProfileIcon>
											) : (
												<EllipseIcon
													sx={{ fontSize: '12px' }}
												></EllipseIcon>
											)
										}
										isCurrent={isCurrent}
										isFailed={false}
										haveBg={false}
									/>
								)
							}
							if (e.event === OrderStatusTypesEnum.Completed) {
								return (
									<HistoryItem
										key={e.event}
										title={t('order.state.delivered')}
										description={''}
										dateTime={dateTime}
										icon={
											<DeliveryHomeIcon
												sx={{
													fontSize: '16px',
													position: 'absolute',
													left: 'calc(50% - 16px / 2)',
													top: 'calc(50% - 16px / 2 - 1px)',
												}}
											/>
										}
										isCurrent={isCurrent}
										isFailed={false}
										haveBg={false}
									/>
								)
							}
							if (e.event === OrderStatusTypesEnum.Failed) {
								return (
									<HistoryItem
										key={e.event}
										title={t('order.state.failed')}
										description={''}
										dateTime={dateTime}
										icon={<CloseIcon sx={{ fontSize: '16px' }} />}
										isCurrent={isCurrent}
										isFailed={false}
										haveBg={false}
									/>
								)
							}

							return null
						})
						.filter(P.isDefined)
						.map((w, index) => (
							<CSSTransition key={index} timeout={200}>
								{w}
							</CSSTransition>
						))
						.reverse()}
				</TransitionGroup>
			</List>
		</Paper>
	)
}

export default OrderHistory

const HistoryItem = ({ title, description, dateTime, icon, isCurrent, isFailed, haveBg }: HistoryItemProps) => {
	const theme = useTheme()

	let orderIcon: OrderIcon = isCurrent ? theme.palette.orderIcon.current : theme.palette.orderIcon.past
	orderIcon = isFailed ? theme.palette.orderIcon.failed : orderIcon

	const textColor: string = isCurrent ? theme.palette.text.primary : theme.palette.text.secondary

	return (
		<ListItem sx={{ p: 0 }}>
			<ListItemAvatar sx={{ minWidth: '24px', mr: '16px' }}>
				<Avatar
					sx={{
						bgcolor: haveBg ? orderIcon.main : orderIcon.contrastText,
						width: '24px',
						height: '24px',
						color: haveBg ? orderIcon.contrastText : orderIcon.main,
					}}
				>
					{icon}
				</Avatar>
			</ListItemAvatar>
			<ListItemText
				primary={
					<Typography sx={{ display: 'block' }} component="span" variant="h5_custom" color={textColor}>
						{title}
					</Typography>
				}
				secondary={
					description ? (
						<>
							<Typography
								sx={{ display: 'block', mt: '4px' }}
								component="span"
								variant="body2_custom"
								color={textColor}
							>
								{description}
							</Typography>
							<Typography
								sx={{ display: 'block', mt: '4px' }}
								component="span"
								variant="body2_custom"
								color={textColor}
							>
								{dateTime}
							</Typography>
						</>
					) : (
						<Typography
							sx={{ display: 'block', mt: '4px' }}
							component="span"
							variant="body2_custom"
							color={textColor}
						>
							{dateTime}
						</Typography>
					)
				}
				sx={{ my: '8px' }}
			/>
		</ListItem>
	)
}

interface HistoryItemProps {
	title: string
	description?: string
	dateTime: string
	icon: any
	isCurrent: boolean
	isFailed: boolean
	haveBg: boolean
}

import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'

const MapMarker = ({ size, icon }: any) => {
	return (
		<Box className="custom-marker" sx={{ position: 'relative' }}>
			<Avatar
				sx={{
					bgcolor: '#3B3B3B',
					width: size,
					height: size,
					color: '#FFFFFF',
					position: 'absolute',
					left: '50%',
					ml: `calc(-${size} * 0.5)`,
					bottom: '9px',
				}}
			>
				{icon}
			</Avatar>
			<Box className="pin-rect" sx={{ width: '3px', height: '10px', bgcolor: '#3B3B3B' }}></Box>
		</Box>
	)
}

export default MapMarker

import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
	palette: {
		neutral: {
			main: '#bbb',
			contrastText: '#999999',
		},
		neutral2: {
			main: '#ffffff',
		},
		// text: {
		// 	primary: '#3B3B3B',
		// 	secondary: '#999999',
		// },
		background: {
			default: '#E5E5E5',
			paper: '#FFFFFF',
		},
		orderIcon: {
			past: {
				main: '#999999',
				contrastText: '#FFFFFF',
			},
			current: {
				main: '#3B3B3B',
				contrastText: '#FFFFFF',
			},
			failed: {
				main: '#B5252E',
				contrastText: '#FFFFFF',
			},
		},
	},
	shape: {
		borderRadius: 5,
	},
	mixins: {
		toolbar: {},
		// toolbar: {
		// 	minHeight: '60px',
		// 	'@media (min-width:0px) and (orientation: landscape)': {
		// 		minHeight: '60px',
		// 	},
		// 	'@media (min-width:600px)': {
		// 		minHeight: '60px',
		// 	}
		// },
	},
	status: {
		danger: '#e53e3e',
	},
	sizes: {
		toolbar: {
			height: {
				big: '80px',
				small: '60px',
			},
		},
	},
	typography: {
		fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'",
		h1_custom: {
			fontSize: '64px',
			lineHeight: '74.4px',
			fontWeight: 400,
		},
		h2_custom: {
			fontSize: '32px',
			lineHeight: '38.4px',
			fontWeight: 400,
		},
		h3_custom: {
			fontSize: '22.78px',
			lineHeight: '27.34px',
			fontWeight: 600,
		},
		h4_custom: {
			fontSize: '20.25px',
			lineHeight: '24.3px',
			fontWeight: 600,
		},
		h5_custom: {
			fontSize: '18px',
			lineHeight: '21.6px',
			fontWeight: 600,
		},
		subtitle1_custom: {
			fontSize: '16px',
			lineHeight: '24px',
			fontWeight: 600,
		},
		subtitle2_custom: {
			fontSize: '16px',
			lineHeight: '19.2px',
			fontWeight: 600,
		},
		subtitle3_custom: {
			fontSize: '16px',
			lineHeight: '24px',
			fontWeight: 400,
		},
		body1_custom: {
			fontSize: '12.64px',
			lineHeight: '15.8px',
			fontWeight: 600,
		},
		body2_custom: {
			fontSize: '12.64px',
			lineHeight: '15.8px',
			fontWeight: 400,
		},
		button_custom: {
			fontSize: '14.22px',
			lineHeight: '24px',
			fontWeight: 600,
		},
	},
})

theme.shadows.push('0px 2px 4px rgba(0, 0, 0, 0.07)')

declare module '@mui/material/styles/createTypography' {
	interface TypographyOptions {
		h1_custom: any
		h2_custom: any
		h3_custom: any
		h4_custom: any
		h5_custom: any
		subtitle1_custom: any
		subtitle2_custom: any
		subtitle3_custom: any
		body1_custom: any
		body2_custom: any
		button_custom: any
	}
	interface Typography {
		h1_custom: any
		h2_custom: any
		h3_custom: any
		h4_custom: any
		h5_custom: any
		subtitle1_custom: any
		subtitle2_custom: any
		subtitle3_custom: any
		body1_custom: any
		body2_custom: any
		button_custom: any
	}
}
declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		h1_custom: true
		h2_custom: true
		h3_custom: true
		h4_custom: true
		h5_custom: true
		subtitle1_custom: true
		subtitle2_custom: true
		subtitle3_custom: true
		body1_custom: true
		body2_custom: true
		button_custom: true
	}
}

declare module '@mui/material/styles' {
	interface Theme {
		palette: Palette
		status: {
			danger: React.CSSProperties['color']
		}
		sizes: Sizes
	}
	interface ThemeOptions {
		palette?: PaletteOptions
		status: {
			danger: React.CSSProperties['color']
		}
		sizes?: Sizes
	}
	interface Palette {
		neutral: Palette['primary']
		neutral2: Palette['primary']
		// text: Palette['text'],
		orderIcon: OrderIconType
	}
	interface PaletteOptions {
		neutral?: PaletteColorOptions
		neutral2?: PaletteColorOptions
		orderIcon: OrderIconType
	}
	interface PaletteColor {
		neutral: string
		neutral2: string
	}
	interface PaletteColorOptions {
		light?: string
		main: string
		dark?: string
		contrastText?: string
	}

	interface Sizes {
		toolbar: ToolbarSizes
	}

	interface ToolbarSizes {
		height: {
			big: string
			small: string
		}
	}

	interface Text {
		failed: string
	}

	interface TextOptions {
		failed: string
	}
	interface TypeText {
		// failed: string
		primary: string
		secondary: string
	}

	interface OrderIconType {
		past: OrderIcon
		current: OrderIcon
		failed: OrderIcon
	}

	export interface OrderIcon {
		main: string
		contrastText: string
	}
}

declare module '@mui/material/Button' {
	interface ButtonPropsColorOverrides {
		slate: true
		test: true
		neutral: true
		neutral2: true
	}
}

declare module '@mui/material/Box' {
	interface BoxPropsColorOverrides {
		test: true
		slate: true
	}
}

import { styled } from '@mui/material/styles'

const SpinnerContainer = styled('div', {
	shouldForwardProp: (prop) => prop !== 'size' && prop !== 'bgColor',
})<{ size: number; bgColor: string }>(({ theme, size, bgColor }) => ({
	position: 'absolute',
	top: 0,
	left: 0,
	display: 'block',
	height: size,
	width: size,
	borderRadius: '50%',
	background: bgColor,
}))

const Spinner = styled('div', {
	shouldForwardProp: (prop) => prop !== 'size' && prop !== 'bgColor',
})<{ size: number; bgColor: string }>(({ theme, size, bgColor }) => ({
	width: '100%',
	height: '100%',
	'@keyframes spin': {
		from: {
			transform: 'rotate(0deg)',
		},
		to: {
			transform: 'rotate(360deg)',
		},
	},
	animation: 'spin 4s linear infinite',

	// Linear gradient
	// '&:before': {
	// 	content: '""',
	// 	position: 'absolute',
	// 	top: 0,
	// 	left: 0,
	// 	zIndex: 1,
	// 	width: '100%',
	// 	height: '50%',
	// 	borderRadius: `${size}px ${size}px 0 0`,
	//   	background: bgColor,
	// },

	// Conic gradient
	background: bgColor,
	borderRadius: '100%',
}))

const SpinnerContent = styled('div', {
	shouldForwardProp: (prop) => prop !== 'size' && prop !== 'bgColor',
})<{ size: number; bgColor: string }>(({ theme, size, bgColor }) => ({
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	zIndex: 2,
	height: size,
	width: size,
	background: bgColor,
	borderRadius: '50%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}))

const CircleSpinner = ({ children, outerSize, innerSize, ...props }: any) => {
	return (
		<SpinnerContainer size={outerSize} bgColor="transparent">
			{/* <Spinner size={208} bgColor='linear-gradient(135deg, #42B25500 50%, #42B255 100%)'></Spinner> */}
			<Spinner size={outerSize} bgColor="conic-gradient(from 90deg at 50% 50%, #42B25500 80.9%, #42B255 100%)"></Spinner>

			<SpinnerContent size={innerSize} bgColor="#FFF">
				{children}
			</SpinnerContent>
		</SpinnerContainer>
	)
}

export default CircleSpinner

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTranslation } from 'react-i18next'
import { useOrderTrackingContext } from '../../pages/OrderTrackingPage/OrderTrackingProvider'
import { OrderStatus } from '../ProgressBar/ProgressBar'
import StatusDetails from '../StatusDetails/StatusDetails'
import StatusProgress from '../StatusProgress/StatusProgress'

function OrderStatusComponent() {
	const theme = useTheme()
	const downMd = useMediaQuery(theme.breakpoints.down('md'))
	const { t } = useTranslation()
	const data = useOrderTrackingContext()

	const state = data.status

	return (
		<>
			<Paper sx={{ display: 'flex', p: '32px 16px 16px 16px' }} elevation={25}>
				<Grid container direction={downMd ? 'row' : 'column'} spacing="32px" wrap="wrap">
					<Grid item xs="auto" sx={{ order: { xs: 1, md: 1 } }}>
						<StatusProgress />
					</Grid>
					<Grid item xs sx={{ flexGrow: { xs: 1 }, flexShrink: { xs: 1 }, order: { xs: 2, md: 3 } }}>
						<StatusDetails />
					</Grid>
					{state === OrderStatus.Failed && (
						<Grid item xs={downMd ? 12 : true} sx={{ order: { xs: 3, md: 2 } }}>
							<Box>
								<Typography component="span" variant="subtitle3_custom">
									{t('order.support')}
								</Typography>
								<Typography component="span" variant="subtitle1_custom">
									(8 5) 230 9309
								</Typography>
								<Typography component="span" variant="subtitle3_custom">
									.
								</Typography>
							</Box>
						</Grid>
					)}
				</Grid>
			</Paper>
		</>
	)
}

export default OrderStatusComponent

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const CheckIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon {...props} width={80} height={80} viewBox="0 0 80 80">
			<path d="M29.3169 52.9166L17.7502 41.3499C17.1274 40.7258 16.2819 40.375 15.4002 40.375C14.5185 40.375 13.673 40.7258 13.0502 41.3499C11.7502 42.6499 11.7502 44.7499 13.0502 46.0499L26.9835 59.9833C28.2835 61.2833 30.3835 61.2833 31.6835 59.9833L66.9502 24.7166C68.2502 23.4166 68.2502 21.3166 66.9502 20.0166C66.3274 19.3924 65.4819 19.0417 64.6002 19.0417C63.7185 19.0417 62.873 19.3924 62.2502 20.0166L29.3169 52.9166Z" />
		</SvgIcon>
	)
}

export default CheckIcon

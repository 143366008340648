import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { differenceInMinutes } from 'date-fns'
import { clamp } from 'ts-prime'
import { useOrderTrackingContext } from '../../pages/OrderTrackingPage/OrderTrackingProvider'
import { OrderStatusTypesEnum } from '../../_generated/graphql'
import ProgressBar, { OrderStatus } from '../ProgressBar/ProgressBar'
import ProgressDetails from '../ProgressDetails/ProgressDetails'

function StatusProgress() {
	const theme = useTheme()
	const downMd = useMediaQuery(theme.breakpoints.down('md'))
	const data = useOrderTrackingContext()

	const state = data?.status
	const orderTracking = data
	if (orderTracking == null) return null

	const arrival = data?.arrival
	if (arrival == null) return null
	const arrivalDate = new Date(arrival)
	const duration = clamp(differenceInMinutes(new Date(arrival), new Date()), { max: state === 'STARTED' ? 60 : 75 })

	const startEvent = data?.events.find((e) => e.event === OrderStatusTypesEnum.Created)?.createdAt
	if (startEvent == null) return null
	const startDate = new Date(startEvent)

	const maxDiff = arrivalDate.getTime() - startDate.getTime()
	const currentDiff = arrivalDate.getTime() - new Date().getTime()
	const progress = 1 - currentDiff / maxDiff

	return (
		<>
			<Box sx={{ display: 'flex' }}>
				<Grid container direction="column" spacing="4px">
					<Grid item xs="auto">
						<ProgressBar state={state} percentage={progress * 100} time={duration}></ProgressBar>
					</Grid>
					{downMd &&
						(state === OrderStatus.Created ||
							state === OrderStatus.Planned ||
							state === OrderStatus.Active ||
							state === OrderStatus.Started ||
							state === OrderStatus.NeedCourier ||
							state === OrderStatus.CourierMissing) && (
							<Grid item xs="auto">
								<ProgressDetails state={state} time={duration}></ProgressDetails>
							</Grid>
						)}
				</Grid>
			</Box>
		</>
	)
}

export default StatusProgress

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const DeliveryHomeIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon {...props} width={16} height={16} viewBox="0 0 16 16">
			<path
				fillRule="evenodd"
				d="M8.40937 1.47375C8.16863 1.2865 7.83152 1.2865 7.59078 1.47374L1.59071 6.14041C1.42832 6.26671 1.33334 6.46092 1.33334 6.66665V14C1.33334 14.3682 1.63181 14.6666 2 14.6666H14C14.3682 14.6666 14.6667 14.3679 14.6667 13.9997V6.66665C14.6667 6.46092 14.5717 6.26672 14.4093 6.14041L8.40937 1.47375ZM14 14L14.6667 13.9997C14.6667 13.9997 14.6667 14 14 14ZM5.32813 13.3333H2.66667V6.99271L8.00007 2.84455L13.3333 6.9927V13.3333H10.516V8.40818C10.516 8.03999 10.2175 7.74152 9.84931 7.74152H5.99479C5.6266 7.74152 5.32813 8.03999 5.32813 8.40818V13.3333ZM6.66146 13.3333H9.18264V9.07485H6.66146V13.3333Z"
			/>
		</SvgIcon>
	)
}

export default DeliveryHomeIcon

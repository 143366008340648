import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useOrderTrackingContext } from '../../pages/OrderTrackingPage/OrderTrackingProvider'
import { OrderStatusTypesEnum } from '../../_generated/graphql'
import DeliveryHomeIcon from '../CustomSvgIcons/DeliveryHomeIcon'
const StatusDetails = () => {
	const { t } = useTranslation()
	const theme = useTheme()
	const data = useOrderTrackingContext()

	const orderTracking = data
	if (orderTracking === undefined || orderTracking === null) {
		return null
	}
	const orderId = data?.merchantOrderId
	const createOrderEvent = data?.events.find((e) => e.event === OrderStatusTypesEnum.Created)
	const dropOffAddress = data?.dropOff.address

	return (
		<>
			<Box sx={{ display: 'flex' }}>
				<Grid container direction="column">
					<Grid item>
						<Box>
							<Typography
								align="left"
								variant="h3_custom"
								component="div"
								sx={{ overflowWrap: 'anywhere', mb: '4px' }}
							>
								{t('order.name', { code: orderId ? orderId : '' })}
							</Typography>
							<Typography
								align="left"
								variant="body1_custom"
								component="div"
								sx={{ overflowWrap: 'anywhere', mb: '16px' }}
							>
								{createOrderEvent &&
									format(new Date(createOrderEvent.createdAt), 'yyyy-MM-dd HH:mm')}
							</Typography>
							<Typography
								align="left"
								variant="subtitle3_custom"
								component="span"
								sx={{
									overflowWrap: 'anywhere',
									display: 'inline-flex',
									alignItems: 'center',
								}}
							>
								<Avatar
									sx={{
										bgcolor: theme.palette.orderIcon.current.main,
										width: '24px',
										height: '24px',
										color: theme.palette.orderIcon.current.contrastText,
										mr: '8px',
									}}
								>
									<DeliveryHomeIcon
										sx={{
											fontSize: '16px',
											position: 'absolute',
											left: 'calc(50% - 16px / 2)',
											top: 'calc(50% - 16px / 2 - 1px)',
										}}
									></DeliveryHomeIcon>
								</Avatar>
								{dropOffAddress && dropOffAddress}
							</Typography>
						</Box>
					</Grid>
					<Grid item>
						<Box></Box>
					</Grid>
					<Grid item>
						<Box></Box>
					</Grid>
				</Grid>
			</Box>
		</>
	)
}

export default StatusDetails
